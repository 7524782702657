import { PostCardSkeleton } from '../post-card/post-card-skeleton';
import { skeletonFeed } from './post-feed-skeleton.css';

export const PostFeedSkeleton = ({ count = 5 }: { count?: number }) => (
  <div className={skeletonFeed}>
    {Array.from({ length: count }).map((_, idx) => (
      <PostCardSkeleton key={idx} />
    ))}
  </div>
);
