import { Skeleton } from '@synoptic/ui-kit/skeleton/skeleton.js';
import { LayoutCard } from '../layout/card.tsx';
import {
  postCardActions,
  postCardBody,
  postCardContainer,
  postCardContent,
  postCardDate,
  postCardFooter,
  postCardHeader,
} from './post-card.css.ts';
import { postCardReputationBet } from './post-card-reputation-bet/post-card-reputation-bet.css.ts';

export const PostCardSkeleton = () => {
  return (
    <LayoutCard className={postCardContainer}>
      <div className={postCardHeader}>
        <Skeleton height={45} width={45} radius={9999} />
      </div>

      <div className={postCardContent}>
        <div className={postCardBody}>
          <Skeleton height={55} width={200} />
        </div>
        <div className={postCardDate}>
          <Skeleton height={14} width={140} />
        </div>
      </div>

      <div className={postCardReputationBet}>
        <Skeleton />
      </div>

      <div className={postCardFooter}>
        <div className={postCardActions}>
          <Skeleton height={34} radius={32} />
        </div>
      </div>
    </LayoutCard>
  );
};
